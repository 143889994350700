let QUESTIONS = `
What's something surprising that you were afraid of as a child?
What was your favorite food growing up?
What's your favorite meal now?
Was there an embarrassing fashion moment in your adolescence?
What do you think is your best quality?
What qualities or character traits do you want to work on?
What's one of my best qualities?
What do you find sexiest about me?
Is there a personality trait that always ticks you off?
What's the first thing you found attractive about me?
How do you hide anger when you don't want it to show?
How do you talk yourself down from anger or frustration?
What's something you'd like to try doing but haven't worked up the nerve to yet?
What would you do with your life if you suddenly became a billionaire?
What's your favorite thing about our relationship?
What's something you'd like for us to try sexually?
What's something not obviously sexual that turns you on?
Do you have a sexual fantasy you've never shared with me before?
Would you rather have only morning sex for the rest of your life or strictly sex at night?
What's your favorite sex position?
Where would you like to be touched more often?
What's something you wish we did together more often?
What historical era would you most, and least, want to go to?
Which song describes our love story best?
What animal would you choose to be in another life?
If you had the option to live forever, would you choose to?
What's the best trip you've ever taken?
Would you rather live in only hot weather forever or only cold weather?
Is there anything you've always wondered about me but have hesitated to ask?
Did you use to have any convictions about relationships that aren’t true anymore?
Do you have any phobias? Did you use to have any phobias?
What did you use to think you’d work as?
What’s the most embarrassed you’ve ever been? What caused it?
What do you think I love/admire most about you?
What do you think is your best quality? Are you happy with that?
Are you happy with the amount of time we spend together?
What wouldn’t you do under any circumstance for your best friend?
What’s one annoying thing about me that you’d change?
Is our relationship hard to navigate for you sometimes?
What’s one country you’ve always wanted to visit?
If you could have anything in the world, what would it be?
Does wealth bring happiness?
What’s something about your everyday life that you’re very happy with?
What was your favorite school subject growing up?
What do you consider to be your love language?
What is the longest period you can stand us being apart?
Which three of my physical affectionate gestures do you genuinely love about me and wish I would do more?
What is a life question you wish you knew the solution to?
If you were invisible for a day, what would you do?
What powers would you have if you were a superhero?
What is the strangest thing you have searched for on Google in the last week?
What would you like for your last supper if today were your last day?
What was the worst mistake you ever made?
When do you find it hard to ask for help?
When you’re feeling low, how do you like to be supported?
Can you remember the last time you were truly happy?
What’s something you think turns me on but I’m afraid to admit?
What’s one positive thing you’ve learned from me since we’ve been together?
Is there anything you haven’t told me because you’re afraid I’d judge you? 
What is one dream you’d like to see come true this week/month/year?
What is something you've always wanted to experience together?
Would you rather have a partner who is more affectionate or more verbal with their feelings?
Would you rather have a partner who surprises you frequently or a partner who plans special dates in advance?
Would you rather have a partner who is more spontaneous or more predictable?
Would you rather have a partner who values quality time or a partner who values acts of service?
Would you rather have a partner who is more adventurous or more grounded?
Would you rather explore a new city together or relax on a secluded beach?
Would you rather go on a road trip across the country or backpack through different countries?
Would you rather explore ancient ruins or visit modern architectural wonders?
Would you rather have a deep, passionate kiss or a series of gentle, tender kisses?
Would you rather have a slow, intimate dance with your partner or a passionate make-out session?
Would you rather have a date night of sharing your deepest secrets or a date night of exploring each other’s bodies through touch?
Would you rather have a date night of slow, passionate lovemaking or a date night of playful and adventurous experimentation?
Would you rather have a date night of cuddling and watching a romantic movie or a date night of writing and sharing love letters?

Would you rather have a partner who enjoys cooking and prepares meals for you or a partner who takes you out to try new restaurants?
Would you rather cook a romantic dinner together or order takeout and watch a movie?
Would you rather have a candlelit dinner at home or dine at a fancy restaurant?
Would you rather express your love through words or actions?
Would you rather have a partner who remembers important dates or a partner who surprises you randomly?
Would you rather have long, heartfelt talks or enjoy comfortable silences together?
Would you rather play board games or video games for a fun night in?
Would you rather have a picnic in the park or go for a romantic boat ride?
Would you rather live in a bustling city or a peaceful countryside?
Would you rather have a partner who shares all your hobbies or a partner with complementary interests?
Would you rather travel the world together or build a stable home life?
`;

QUESTIONS = QUESTIONS.split("\n")
  .map((q) => q.trim())
  .filter((q) => q.length > 0);

export default QUESTIONS;
