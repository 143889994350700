import logo from "./logo.svg";
import QUESTIONS from "./questions";
import { useState } from "react";
import "./App.css";

/*
https://www.oprahdaily.com/life/relationships-love/a34453058/questions-for-couples/
https://www.bettertopics.com/blog/thought-provoking-questions-for-couples/
https://byliner.com/50-deep-thought-provoking-questions-for-couples/
https://www.personalcreations.com/blog/deep-questions
https://21ninety.com/would-you-rather-questions-for-couple
*/
function getRandomQuestion() {
  return QUESTIONS[Math.floor(Math.random() * QUESTIONS.length)];
}

function App() {
  const [question, setQuestion] = useState(getRandomQuestion());
  function handleOnClick() {
    setQuestion(getRandomQuestion());
  }

  return (
    <>
      <div
        onClick={handleOnClick}
        className="flex justify-center items-center  min-h-[90vh]  bg-zinc-900"
      >
        <h1 className="text-3xl md:text-5xl  mb-10  text-white  font-serif mx-20 text-center leading-relaxed">
          {question}
        </h1>
      </div>
      <div className="text-white bg-zinc-900 min-h-[10vh] flex justify-center items-center">
        <div className="mr-5 text-xl font-serif italic">
          Made with ❤️ by jwt x crt
        </div>
      </div>
    </>
  );
}

export default App;
